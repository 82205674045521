import { getPermissions } from './onAppDataLoad';
import { PermissionsSuiteWrapper } from './wrapper';
import type { SuitePlugin } from '@theorchard/suite-frontend';
import type { ExpectedAny } from 'src/types';

export const PermissionsPlugin: SuitePlugin<ExpectedAny> = {
    name: 'PermissionsPlugin',
    onAppDataLoad: getPermissions,
    Wrapper: PermissionsSuiteWrapper,
};
