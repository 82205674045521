export const RESOURCE_TYPE_ACTIONS: { resourceType: string; action: string }[] =
    [
        {
            resourceType: 'ad_account',
            action: 'edit',
        },
        {
            resourceType: 'ad_campaign',
            action: 'view',
        },
        {
            resourceType: 'audience',
            action: 'access',
        },
        {
            resourceType: 'audience',
            action: 'create_ad_target',
        },
        {
            resourceType: 'audience',
            action: 'create_email_target',
        },
        {
            resourceType: 'audience',
            action: 'share_ad_target',
        },
        {
            resourceType: 'email_campaign',
            action: 'view',
        },
        {
            resourceType: 'email_campaign',
            action: 'create',
        },
        {
            resourceType: 'email_campaign',
            action: 'delete',
        },
        {
            resourceType: 'email_campaign',
            action: 'edit',
        },
        {
            resourceType: 'email_campaign',
            action: 'send',
        },
        {
            resourceType: 'roster',
            action: 'view',
        },
    ];
