import QUERY from 'src/apollo/queries/tenantRoles/tenantRoles.gql';
import type { GraphqlClient } from '@theorchard/suite-frontend';
import type {
    TenantRolesQuery as Query,
    TenantRolesQueryVariables as Variables,
} from 'src/apollo/definitions/tenantRoles';

export const getAuthorizedTenants = async (
    graphqlClient: GraphqlClient,
    { identityId, resourceTypeActions }: Variables
) => {
    const result = await graphqlClient.query<Query, Variables>({
        query: QUERY,
        variables: {
            identityId,
            resourceTypeActions,
        },
    });

    if (!result.data.identityById) {
        throw new Error('Identity not found');
    }

    const authorizedTenants = result.data.identityById.isAuthorizedForTenants;

    return authorizedTenants;
};

export type AuthorizedTenants = Awaited<
    ReturnType<typeof getAuthorizedTenants>
>[number];
