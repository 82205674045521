import { useMemo } from 'react';
import { PermissionsContext } from './context';
import type { PermissionsPluginData } from './types';
import type { SuiteWrapper } from '@theorchard/suite-frontend';

export type PermissionsContextProviderProps = {
    children?: React.ReactNode;
    data: {
        appData?: Pick<PermissionsPluginData, 'permissions'>;
    };
};

export const PermissionsSuiteWrapper = (({
    children,
    data,
}: PermissionsContextProviderProps) => {
    const { appData } = data;

    const permissions = appData?.permissions;

    const value = useMemo(
        () => ({ permissions: permissions ?? [] }),
        [permissions]
    );

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    );
}) satisfies SuiteWrapper<PermissionsPluginData>;
