import { lazy } from 'react';
import { initApplication } from '@theorchard/suite-frontend';
import { NavIcon } from '@theorchard/suite-icons';
import { typePolicies } from 'src/apollo/cache/typePolicies';
import { FeatureFlag } from 'src/constants/featureFlags';
import HomePage from 'src/pages/home';
import { ROUTES } from 'src/pages/routes';
import { withSuspence } from 'src/utils/withSuspence';
import { locales } from '../locale'; // run i18n:init script if module does not exist
import {
    PermissionsPlugin,
    UrlSanitizerPlugin,
    DefaultTitlePlugin,
} from './plugins';
import type { PermissionsPluginData } from './plugins';

export type AppData = Partial<PermissionsPluginData>;

const AudienesPage = lazy(async () => await import('src/pages/audiences'));
const AdReportingPage = lazy(
    async () => await import('src/pages/ad-reporting')
);
const EmailCampaignsPage = lazy(
    async () => await import('src/pages/email-campaigns')
);
const ConnectionsPage = lazy(async () => await import('src/pages/connections'));
const ParticipantPage = lazy(async () => await import('src/pages/participant'));
const SearchPage = lazy(async () => await import('src/pages/search'));
const RosterPage = lazy(async () => await import('src/pages/roster'));

export const render = initApplication<AppData>({
    locales,
    apollo: { typePolicies },
    config: { auth0StoreTokenInClient: true },
    plugins: [PermissionsPlugin, DefaultTitlePlugin, UrlSanitizerPlugin],
    profileType: 'AudienceProfile',
    featureFlagFilter: 'audience',
    colorScheme: {
        V2: () => true,
    },
    mainNav: {
        beta: true,
        collapsible: true,
        sections: [
            {
                id: 'main',
                items: [
                    {
                        path: ROUTES.SEARCH.path,
                        term: 'navigation.search',
                        icon: <NavIcon name="SearchNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                    },
                    {
                        path: ROUTES.ROSTER.path,
                        term: 'navigation.roster',
                        icon: <NavIcon name="CatalogNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                    },
                    {
                        path: ROUTES.AUDIENCES.path,
                        term: 'navigation.audiences',
                        icon: <NavIcon name="UsersNavIcon" />,
                        hasAccess: context => {
                            const hasAccess = context.appData?.hasPermission!(
                                'audience',
                                'access'
                            );
                            const isNewPermissions =
                                context.identity.features[
                                    FeatureFlag.APPLY_NEW_PERMISSIONS
                                ];
                            const hasAnalystRole =
                                context.identity.features[
                                    FeatureFlag.APPLY_ANALYST_ROLE
                                ];

                            return (
                                (isNewPermissions ? hasAccess : true) &&
                                !hasAnalystRole
                            );
                        },
                    },
                    {
                        path: ROUTES.AD_REPORTING.path,
                        term: 'navigation.adReporting',
                        icon: <NavIcon name="AdReportingNavIcon" />,
                        hasAccess: context => {
                            const hasAccess = context.appData?.hasPermission!(
                                'ad_campaign',
                                'view'
                            );
                            const isNewPermissions =
                                context.identity.features[
                                    FeatureFlag.APPLY_NEW_PERMISSIONS
                                ];
                            const isFFEnabled =
                                context.identity.features[
                                    FeatureFlag.SHOW_AD_REPORTING
                                ];

                            const isAppReviewView =
                                context.identity.features[
                                    FeatureFlag.APPLY_APP_REVIEW_VIEW
                                ];

                            return (
                                (isNewPermissions ? hasAccess : isFFEnabled) &&
                                !isAppReviewView
                            );
                        },
                    },
                    {
                        path: ROUTES.EMAIL_CAMPAIGNS.path,
                        term: 'navigation.emailCampaigns',
                        icon: <NavIcon name="EmailNavIcon" />,
                        hasAccess: context => {
                            const hasAccess = context.appData?.hasPermission!(
                                'email_campaign',
                                'view'
                            );
                            const isNewPermissions =
                                context.identity.features[
                                    FeatureFlag.APPLY_NEW_PERMISSIONS
                                ];
                            const isFFEnabled =
                                context.identity.features[
                                    FeatureFlag.SHOW_EMAIL_CAMPIGNS_PAGE
                                ];
                            const isAppReviewView =
                                context.identity.features[
                                    FeatureFlag.APPLY_APP_REVIEW_VIEW
                                ];

                            return (
                                (isNewPermissions ? hasAccess : isFFEnabled) &&
                                !isAppReviewView
                            );
                        },
                    },
                ],
            },
            {
                id: 'secondary',
                type: 'secondary',
                items: [
                    {
                        path: ROUTES.CONNECTIONS.path,
                        term: 'navigation.appConnections',
                        icon: <NavIcon name="AppConnectionsNavIcon" />,
                        hasAccess: context => {
                            const isAppReviewView =
                                context.identity.features[
                                    FeatureFlag.APPLY_APP_REVIEW_VIEW
                                ];

                            if (isAppReviewView) return true;

                            const isNewPermissions =
                                context.identity.features[
                                    FeatureFlag.APPLY_NEW_PERMISSIONS
                                ];

                            if (isNewPermissions) {
                                const hasAccess =
                                    context.appData?.hasPermission!(
                                        'ad_account',
                                        'edit'
                                    ) ?? false;
                                const isShopifySectionEnabled =
                                    context.identity.features[
                                        FeatureFlag.SHOW_SHOPIFY_STORES_SECTION
                                    ];

                                return hasAccess || isShopifySectionEnabled;
                            }

                            const isConnectionsEnabled =
                                context.identity.features[
                                    FeatureFlag.SHOW_APP_CONNECTIONS
                                ];
                            return isConnectionsEnabled;
                        },
                    },
                ],
            },
        ],
    },
    routes: [
        { path: '/', page: HomePage },
        {
            path: ROUTES.SEARCH.path,
            page: withSuspence(SearchPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            path: ROUTES.ROSTER.path,
            page: withSuspence(RosterPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            path: ROUTES.ARTIST.path,
            page: withSuspence(ParticipantPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            exact: false,
            path: ROUTES.AUDIENCES.path,
            page: withSuspence(AudienesPage),
            hasAccess: context => {
                const hasAccess = context.appData?.hasPermission!(
                    'audience',
                    'access'
                );
                const isNewPermissions =
                    context.identity.features[
                        FeatureFlag.APPLY_NEW_PERMISSIONS
                    ];
                const hasAnalystRole =
                    context.identity.features[FeatureFlag.APPLY_ANALYST_ROLE];

                return (isNewPermissions ? hasAccess : true) && !hasAnalystRole;
            },
        },
        {
            exact: false,
            path: ROUTES.AD_REPORTING.path,
            page: withSuspence(AdReportingPage),
            hasAccess: context => {
                const hasAccess = context.appData?.hasPermission!(
                    'ad_campaign',
                    'view'
                );
                const isNewPermissions =
                    context.identity.features[
                        FeatureFlag.APPLY_NEW_PERMISSIONS
                    ];
                const isFFEnabled =
                    context.identity.features[FeatureFlag.SHOW_AD_REPORTING];

                const isAppReviewView =
                    context.identity.features[
                        FeatureFlag.APPLY_APP_REVIEW_VIEW
                    ];

                return (
                    (isNewPermissions ? hasAccess : isFFEnabled) &&
                    !isAppReviewView
                );
            },
        },
        {
            exact: false,
            path: ROUTES.EMAIL_CAMPAIGNS.path,
            page: withSuspence(EmailCampaignsPage),
            hasAccess: context => {
                const hasAccess = context.appData?.hasPermission!(
                    'email_campaign',
                    'view'
                );
                const isNewPermissions =
                    context.identity.features[
                        FeatureFlag.APPLY_NEW_PERMISSIONS
                    ];
                const isFFEnabled =
                    context.identity.features[
                        FeatureFlag.SHOW_EMAIL_CAMPIGNS_PAGE
                    ];
                const isAppReviewView =
                    context.identity.features[
                        FeatureFlag.APPLY_APP_REVIEW_VIEW
                    ];

                return (
                    (isNewPermissions ? hasAccess : isFFEnabled) &&
                    !isAppReviewView
                );
            },
        },
        {
            path: ROUTES.CONNECTIONS.path,
            page: withSuspence(ConnectionsPage),
            hasAccess: context => {
                const isAppReviewView =
                    context.identity.features[
                        FeatureFlag.APPLY_APP_REVIEW_VIEW
                    ];

                if (isAppReviewView) return true;

                const isNewPermissions =
                    context.identity.features[
                        FeatureFlag.APPLY_NEW_PERMISSIONS
                    ];

                if (isNewPermissions) {
                    const hasAccess =
                        context.appData?.hasPermission!('ad_account', 'edit') ??
                        false;
                    const isShopifySectionEnabled =
                        context.identity.features[
                            FeatureFlag.SHOW_SHOPIFY_STORES_SECTION
                        ];

                    return hasAccess || isShopifySectionEnabled;
                }

                const isConnectionsEnabled =
                    context.identity.features[FeatureFlag.SHOW_APP_CONNECTIONS];
                return isConnectionsEnabled;
            },
        },
    ],
});
