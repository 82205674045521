/* eslint-disable */
/*
*   NOTE
*   This file is auto generated by @theorchard/frontend-cli-i18n.
*   Any manual changes will be overwritten!
*/
import en from './default.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import pt from './pt.json';
import ru from './ru.json';
import tr from './tr.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

export const locales = {
    'en': en,
    'de': de,
    'es': es,
    'fr': fr,
    'it': it,
    'ja': ja,
    'ko': ko,
    'pt': pt,
    'ru': ru,
    'tr': tr,
    'zh-CN': zhCN,
    'zh-TW': zhTW,
};

export default locales;
