import { RESOURCE_TYPE_ACTIONS } from '../../permissions/constants';
import { checkPermission } from './utils/checkPermission';
import { getAuthorizedTenants } from './utils/getAuthorizedTenants';
import type { Permission, PermissionsPluginData } from './types';
import type {
    AppContext,
    OnAppDataLoadCallback,
} from '@theorchard/suite-frontend';

export const getPermissions = (async ({
    identity,
    graphqlClient,
}: Pick<
    AppContext,
    'graphqlClient' | 'identity'
>): Promise<PermissionsPluginData> => {
    const resourcesAuthorizedTenants = await getAuthorizedTenants(
        graphqlClient,
        {
            identityId: identity.id,
            resourceTypeActions: RESOURCE_TYPE_ACTIONS,
        }
    );

    const permissions = resourcesAuthorizedTenants.map<Permission>(
        resourceTypeActionTenants => {
            return {
                action: resourceTypeActionTenants.action,
                resourceType: resourceTypeActionTenants.resourceType,
                tenants: resourceTypeActionTenants.tenants,
            };
        }
    );

    return {
        permissions,
        hasPermission: checkPermission(permissions),
    };
}) satisfies OnAppDataLoadCallback<PermissionsPluginData>;
