import { template } from 'lodash';
import type { PathTo, Routes, RoutesConfig } from './types';
import type { SupportedUrlParams } from 'src/hooks/useSearchParams/useSearchParams';

const pathTemplate = (path: string) =>
    template(path, { interpolate: /:(\w+)/g });

export const initRoutes = <
    const P extends `/${string}`,
    const T extends RoutesConfig,
>(
    rootPath: P,
    config: T
): Routes<P, T> => {
    const routes: Record<
        string,
        {
            path: string;
            pathTo: PathTo<string>;
            search?: Record<string, (param: string) => SupportedUrlParams>;
            searchParams?: Record<string, string>;
        }
    > = {};

    const basePath = rootPath === ('/' as `/${string}`) ? '' : rootPath;

    for (const key in config) {
        const route = config[key];
        const routePath = route.path === '/' ? '' : route.path;

        const path = `${basePath}${routePath}`;

        routes[key] = {
            path: path,
            pathTo: pathTemplate(path),
        };

        if (route.search) {
            routes[key].search = route.search;
            routes[key].searchParams = Object.fromEntries(
                Object.keys(route.search).map(key => [key, key])
            );
        }
    }

    return routes as Routes<P, T>;
};
